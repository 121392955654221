import { LoadingButton } from '@mui/lab';
import { Box, Button, Skeleton, Stack, TextField, Typography, useTheme } from '@mui/material';
import { PlusFillIcon } from 'src/assets';
import { DraggableList } from 'src/features/sites/components/draggable-tag-list';
import React, { useEffect, useState } from 'react';
import { useAuthentication } from 'src/features/authentication/context';
import { PositioningTypes, WidgetService } from 'src/services';
import { boxWrapperStyle, stackStyle } from './style';
import { useDraggableList } from 'src/hooks/useDraggableList';
import { RowType } from './types';

type EditLiveDataWidgetModal = {
  onClose?: () => void;
  title: string;
  widgetId?: string;
  pos?: PositioningTypes;
  toggleAddOrRemoveHandler?: () => void;
  formttedTags: any;
};

const ModalEditLiveDataWidget = ({
  onClose,
  title,
  widgetId,
  pos,
  toggleAddOrRemoveHandler,
  formttedTags,
}: EditLiveDataWidgetModal) => {
  const theme = useTheme();
  const {
    customerId: { value: customerId },
    siteId: { value: siteId },
  } = useAuthentication();

  const [initialRows, setRows] = useState<{ value: RowType[]; loaded: boolean }>({
    value: Array.from({ length: 1 }, (v, k) => k).map((k) => ({
      id: `${k + 1}`,
      position: k + 1,
      tagId: '',
      gatewayId: '',
      parentType: 'gateway',
    })),
    loaded: false,
  });

  const {
    addRow,
    rows,
    onDragEnd,
    handleSetRow,
    removeRow,
    dropdownData,
    isTagFetchOver,
    setMobileListWidth,
    selectedTags,
    setIsSubmitting,
    isSubmitting,
  } = useDraggableList({
    isLiveDataWidget: true,
    title: title,
    initialRows,
    selectedSite: siteId,
  });

  const breakLine = () => <Box sx={{ background: 'rgba(145, 158, 171, 0.24)', height: '1px' }} />;

  const [widgetName, setWidgetName] = useState<string>('');

  useEffect(() => {
    if (formttedTags) {
      setRows({
        value: formttedTags?.map((tag: any, index: any) => ({
          id: `${index + 1}`,
          position: index + 1,
          tagId: tag.id,
          gatewayId: tag.gatewayId || '',
          tag: tag,
        })),
        loaded: true,
      });
    }
  }, [formttedTags]);

  const disableSaveBtn = () => {
    if (rows.length < 1) return true;
    return Object.values(rows).every((row) => row.tag === null);
  };
  const handleSave = async () => {
    if (!customerId || !siteId || !widgetId) {
      return;
    }
    setIsSubmitting(true);
    try {
      await WidgetService.edit({
        customerId: customerId,
        siteId: siteId,
        widgetId: widgetId,
        data: {
          title: widgetName === '' ? title : widgetName,
          tags: rows?.map((tag) => ({ id: tag.tagId, position: tag.position })),
          positioning: pos,
        },
      });
      if (toggleAddOrRemoveHandler) toggleAddOrRemoveHandler();
      onClose?.();
    } catch (error) {
      console.error('Error saving widget:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleMobileListWidth = (value: string) => {
    setMobileListWidth(value);
  };

  return (
    <Box
      sx={{ ...boxWrapperStyle, backgroundColor: theme.palette.background.default }}
      data-sm="edit-widget-modal"
    >
      <Box sx={{ padding: '24px' }} component="header">
        <Typography variant="h6">Edit Widget</Typography>
        <Typography variant="caption">
          Select tags for the live data widget. Drag and drop to define the order.
        </Typography>
      </Box>

      <Box sx={{ padding: '15px', paddingTop: '5px' }}>
        <TextField
          label="Enter a widget name"
          size="small"
          fullWidth
          value={widgetName}
          onChange={(e) => setWidgetName(e.target.value)}
        />
      </Box>
      {breakLine()}
      <Box
        sx={{
          overflowX: 'auto',
          maxWidth: '100%',
          '&::-webkit-scrollbar': {
            height: '8px',
            width: '8px',
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: 'transparent',
            borderRadius: '4px',
          },
          '&::-webkit-scrollbar-thumb': {
            background: `${theme.scrollbar?.thumb}`,
            borderRadius: '4px',
          },
          '&::-webkit-scrollbar-thumb:hover': {
            background: `${theme.scrollbar?.thumbHover}`,
          },
        }}
      >
        <Stack sx={stackStyle}>
          {isTagFetchOver && dropdownData.loaded ? (
            <DraggableList
              items={rows}
              selectedTags={selectedTags}
              onDragEnd={onDragEnd}
              onRemoveRow={removeRow}
              dropdownData={dropdownData}
              handleSetRow={handleSetRow}
              handleMobileListWidth={handleMobileListWidth}
            />
          ) : (
            <>
              {Array.from({ length: 4 }, (_, k) => (
                <Skeleton
                  animation="wave"
                  variant="rounded"
                  height={'40px'}
                  width={'100%'}
                  sx={{ bgcolor: theme.palette.grey[300], mt: '12px' }}
                  key={k}
                />
              ))}
            </>
          )}
        </Stack>
      </Box>

      {breakLine()}
      <Stack
        sx={{ width: '100%', padding: '24px' }}
        flexDirection={'row'}
        justifyContent={'space-between'}
      >
        <Button
          size={'medium'}
          variant="text"
          startIcon={<PlusFillIcon />}
          data-sm="add-new-tag"
          data-testid="add-new-tag"
          onClick={addRow}
          disabled={rows.length >= 6}
        >
          {'Add New Tag'}
        </Button>
        <Stack flexDirection={'row'}>
          <Button
            size={'medium'}
            variant="outlined"
            data-sm="cancel-modal-update"
            onClick={onClose}
          >
            {'Cancel'}
          </Button>
          <LoadingButton
            loading={isSubmitting}
            size={'medium'}
            variant="contained"
            data-sm="save-modal-data"
            sx={{ ml: '8px' }}
            onClick={handleSave}
            disabled={disableSaveBtn()}
          >
            {'Save'}
          </LoadingButton>
        </Stack>
      </Stack>
    </Box>
  );
};

export default React.memo(ModalEditLiveDataWidget);
